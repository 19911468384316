// /src/pages/Reviews.jsx
import React, { useEffect, useState } from "react";
import axiosClient from "../api/axiosClient";
import { FaStar, FaRegStar, FaCheckCircle } from "react-icons/fa";

// Se reutiliza el componente RatingGauge para mostrar la valoración
// (Ahora ya no se utilizará en la vista de estadísticas)
// const RatingGauge = ({ averageRating, currentAverage }) => {
//   // console.log(
//   //   "averageRating:",
//   //   averageRating,
//   //   "currentAverage:",
//   //   currentAverage
//   // );

//   const normalizeRating = (rating) => Math.min(Math.max(rating, 0), 5);
//   const normalizedInitial = normalizeRating(averageRating);
//   const normalizedCurrent = normalizeRating(currentAverage);

//   const percentageInitial = normalizedInitial / 5;
//   const percentageCurrent = normalizedCurrent / 5;

//   const cx = 100;
//   const cy = 100;
//   const r = 80;
//   const strokeWidth = 12;
//   const totalAngle = 180;

//   const degToRad = (deg) => (deg * Math.PI) / 180;

//   const polarToCartesian = (cx, cy, r, angleDeg) => {
//     const angleRad = degToRad(angleDeg);
//     return {
//       x: cx + r * Math.cos(angleRad),
//       y: cy + r * Math.sin(angleRad),
//     };
//   };

//   // Background arc
//   const bgStart = polarToCartesian(cx, cy, r, 180);
//   const bgEnd = polarToCartesian(cx, cy, r, 360);
//   const bgPath = `M ${bgStart.x} ${bgStart.y} A ${r} ${r} 0 1 1 ${bgEnd.x} ${bgEnd.y}`;

//   // Initial rating arc
//   const initialAngle = totalAngle * percentageInitial;
//   const initialEnd = polarToCartesian(cx, cy, r, 180 + initialAngle);
//   const initialArcFlag = initialAngle > 180 ? "1" : "0";
//   const initialPath = `M ${bgStart.x} ${bgStart.y} A ${r} ${r} 0 ${initialArcFlag} 1 ${initialEnd.x} ${initialEnd.y}`;

//   // Current rating arc (mismo radio que el inicial)
//   const currentAngle = totalAngle * percentageCurrent;
//   const currentEnd = polarToCartesian(cx, cy, r, 180 + currentAngle);
//   const currentArcFlag = currentAngle > 180 ? "1" : "0";
//   const currentPath = `M ${bgStart.x} ${bgStart.y} A ${r} ${r} 0 ${currentArcFlag} 1 ${currentEnd.x} ${currentEnd.y}`;

//   // Determine if there's an improvement
//   const hasImproved = currentAverage > averageRating;
//   const currentColor = hasImproved ? "#2563eb" : "#ef4444"; // blue-600 for improvement, red-500 for decline

//   return (
//     <svg width="200" height="150" viewBox="0 0 200 150">
//       {/* Background arc */}
//       <path
//         d={bgPath}
//         fill="none"
//         stroke="#e5e5e5"
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//       />

//       {/* Current rating arc - ahora va primero para que esté debajo */}
//       {hasImproved && (
//         <path
//           d={currentPath}
//           fill="none"
//           stroke={currentColor}
//           strokeWidth={strokeWidth}
//           strokeLinecap="round"
//           strokeDasharray={`${strokeWidth * 0.8}, ${strokeWidth * 0.4}`}
//         />
//       )}

//       {/* Initial rating arc - ahora va después para que esté encima */}
//       <path
//         d={initialPath}
//         fill="none"
//         stroke="#4CAF50"
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//       />

//       {/* Text values */}
//       <text
//         x="100"
//         y="90"
//         textAnchor="middle"
//         fontSize="24"
//         fill="#333"
//         fontWeight="bold"
//       >
//         {normalizedCurrent.toFixed(2)}
//       </text>

//       <text x="100" y="115" textAnchor="middle" fontSize="14" fill="#666">
//         de 5 {hasImproved ? "↑" : ""}
//       </text>

//       {/* Legend */}
//       <g transform="translate(50, 140)" fontSize="10">
//         <circle cx="0" cy="0" r="4" fill="#4CAF50" />
//         <text x="8" y="3" fill="#666">
//           Inicial {normalizedInitial.toFixed(2)}
//         </text>

//         {hasImproved && (
//           <>
//             <circle cx="80" cy="0" r="4" fill={currentColor} />
//             <text x="88" y="3" fill="#666">
//               Actual {normalizedCurrent.toFixed(2)}
//             </text>
//           </>
//         )}
//       </g>
//     </svg>
//   );
// };

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [stats, setStats] = useState(null);
  const [error, setError] = useState("");
  const [selectedReview, setSelectedReview] = useState(null);
  const [loading, setLoading] = useState(true);

  // Filtros
  const [ratingFilter, setRatingFilter] = useState("ALL");
  const [sortOrder, setSortOrder] = useState("desc");

  // Nuevo estado para el buscador por nombre
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchReviews();
  }, []);

  // Función para formatear fechas a DD/MM/YYYY
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Filtrar reseñas por búsqueda (por nombre) y por rating
  const filteredReviews = reviews
    .filter((r) => {
      let matchesName = true;
      if (searchQuery.trim() !== "") {
        matchesName =
          r.name && r.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
      let matchesRating = true;
      if (ratingFilter !== "ALL") {
        matchesRating = r.rating === ratingFilter;
      }
      return matchesName && matchesRating;
    })
    .sort((a, b) => {
      const dateA = new Date(a.createTime);
      const dateB = new Date(b.createTime);
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });

  const fetchReviews = async () => {
    try {
      const response = await axiosClient.get("/reviews");
      // Se espera que el endpoint retorne { userId, reviews, stats }
      // console.log("Reviews response:", response);
      setReviews(response.reviews || []);
      setStats(response.stats || null);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError(err.message || "Error fetching reviews");
    } finally {
      setLoading(false);
    }
  };

  const handleRatingFilterChange = (e) => {
    setRatingFilter(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const parseRatingToNumber = (rating) => {
    switch (rating) {
      case "FIVE":
        return 5;
      case "FOUR":
        return 4;
      case "THREE":
        return 3;
      case "TWO":
        return 2;
      case "ONE":
        return 1;
      default:
        return 0;
    }
  };

  const renderStars = (rating) => {
    const numeric = parseRatingToNumber(rating);
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= numeric ? (
          <FaStar key={i} className="text-yellow-400 inline-block" />
        ) : (
          <FaRegStar key={i} className="text-yellow-400 inline-block" />
        )
      );
    }
    return stars;
  };

  // Calcular el incremento de reseñas: diferencia entre total y el valor inicial
  const reviewsIncrease =
    stats && stats.initialReviewCount
      ? Number(stats.totalReviewCount) - Number(stats.initialReviewCount)
      : 0;

  return (
    <div className="min-h-screen bg-neutral-lightGray p-6">
      <h1 className="font-heading text-h2 text-primary-dark mb-6">Reseñas</h1>

      {/* Sección de estadísticas del negocio */}
      {/* {stats && (
        <div className="mb-6 p-4 bg-neutral-white rounded-lg shadow-card">
          <div className="flex flex-row justify-around items-center">
            <div className="text-center">
              <p className="font-bold text-4xl text-neutral-black">
                {stats.totalReviewCount} Reseñas
              </p>
              {stats.initialReviewCount &&
                Number(stats.initialReviewCount) > 0 && (
                  <p className="text-2xl font-bold text-state-success">
                    +{reviewsIncrease} desde el inicio
                  </p>
                )}
            </div>
            <div className="text-center">
              <p className="text-xs text-neutral-darkGray">
                Última actualización:{" "}
                {stats.lastUpdated ? formatDate(stats.lastUpdated) : "-"}
              </p>
              <p className="text-sm text-neutral-darkGray">
                Desde:{" "}
                {stats.initialDate
                  ? new Date(stats.initialDate).toLocaleDateString("es-ES")
                  : "N/A"}{" "}
                / Inicial: {stats.initialReviewCount || 0}
              </p>
            </div>
          </div>
        </div>
      )} */}

      {error && (
        <div className="bg-state-error text-neutral-white p-3 rounded mb-3">
          Ocurrió un error: {error}
        </div>
      )}

      {/* Filtros */}
      <div className="flex flex-col sm:flex-row items-center mb-6 gap-4">
        <div className="flex flex-col">
          <label className="text-sm text-neutral-black mb-1 font-semibold">
            Filtrar por Rating:
          </label>
          <select
            value={ratingFilter}
            onChange={handleRatingFilterChange}
            className="border border-neutral-mediumGray rounded p-1 bg-neutral-white"
          >
            <option value="ALL">Todos</option>
            <option value="FIVE">5 Estrellas</option>
            <option value="FOUR">4 Estrellas</option>
            <option value="THREE">3 Estrellas</option>
            <option value="TWO">2 Estrellas</option>
            <option value="ONE">1 Estrella</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-sm text-neutral-black mb-1 font-semibold">
            Ordenar por Fecha:
          </label>
          <select
            value={sortOrder}
            onChange={handleSortChange}
            className="border border-neutral-mediumGray rounded p-1 bg-neutral-white"
          >
            <option value="desc">Más recientes primero</option>
            <option value="asc">Más antiguas primero</option>
          </select>
        </div>
      </div>

      {/* Nuevo buscador por nombre */}
      <div className="mb-4">
        <label className="block mb-1 font-medium text-neutral-darkGray">
          Buscar por nombre:
        </label>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Buscar por nombre"
          className="border border-neutral-mediumGray p-2 rounded-md w-full"
        />
      </div>

      {/* Mostrar "Cargando..." mientras se obtienen las reviews */}
      {loading ? (
        <p className="text-neutral-darkGray">Cargando...</p>
      ) : filteredReviews.length === 0 ? (
        <p className="text-neutral-mediumGray">No se encontraron reseñas.</p>
      ) : (
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filteredReviews.map((review) => (
            <div
              key={review.reviewId}
              onClick={() => setSelectedReview(review)}
              className="p-4 bg-neutral-white rounded-lg shadow-card hover:shadow-lg transition-shadow cursor-pointer relative"
            >
              {/* Indicador de review respondida */}
              {review.responseTimestamp && (
                <div className="absolute top-2 right-2 flex items-center gap-1 text-state-success">
                  <FaCheckCircle className="w-4 h-4" />
                  <span className="text-xs font-medium">Respondida</span>
                </div>
              )}

              {/* Encabezado con foto y nombre */}
              <div className="flex items-center mb-2">
                {review.photo ? (
                  <img
                    src={review.photo}
                    alt="Reviewer"
                    className="w-10 h-10 rounded-full mr-2 object-cover"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-neutral-mediumGray mr-2" />
                )}
                <h3 className="text-sm font-semibold text-primary-dark">
                  {review.name || "Usuario Anónimo"}
                </h3>
              </div>

              {/* Rating */}
              <div className="mb-2">{renderStars(review.rating)}</div>

              {/* Comentario (vista corta) */}
              <p className="text-sm text-neutral-darkGray mb-2 line-clamp-4">
                {review.text || "Sin comentarios"}
              </p>

              <p className="text-xs text-neutral-darkGray">
                <strong>Creado:</strong>{" "}
                {review.createTime ? formatDate(review.createTime) : "-"}
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Modal para ver el detalle de la review */}
      {selectedReview && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-neutral-white p-6 rounded-lg shadow-card max-w-md w-full">
            <h2 className="font-heading text-h3 text-neutral-black mb-4">
              Detalle de Reseña
            </h2>
            <p className="mb-2">
              <strong>Reseña:</strong> {selectedReview.text}
            </p>
            {selectedReview.responseText && (
              <p className="mb-2">
                <strong>Respuesta:</strong> {selectedReview.responseText}
              </p>
            )}
            <p className="text-xs text-neutral-darkGray mb-2">
              <strong>Creado:</strong>{" "}
              {selectedReview.createTime
                ? formatDate(selectedReview.createTime)
                : "-"}
            </p>
            {selectedReview.responseTimestamp && (
              <p className="text-xs text-neutral-darkGray mb-2">
                <strong>Respondido:</strong>{" "}
                {formatDate(selectedReview.responseTimestamp)}
              </p>
            )}
            <button
              onClick={() => setSelectedReview(null)}
              className="mt-4 px-4 py-2 bg-primary hover:bg-primary-dark text-neutral-white rounded-md transition duration-300"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reviews;
