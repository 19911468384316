// /src/pages/OAuthCallback.jsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importa el hook de navegación
import axiosClient from "../api/axiosClient"; // Importamos nuestra configuración de Axios

const OAuthCallback = () => {
  const navigate = useNavigate(); // Inicializa el hook para la redirección

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      // Envía el código al backend usando axiosClient
      axiosClient
        .post("/oauthgoogleb", { code }) // La baseURL ya está configurada en axiosClient
        .then((response) => {
          alert("Cuenta conectada con éxito.");
          console.log("Tokens almacenados:", response);

          // Redirige a /connect-google
          navigate("/connect-google");
        })
        .catch((error) => {
          alert("Error al conectar la cuenta de Google.");
          console.error("Error:", error);
        });
    }
  }, [navigate]);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h1 className="text-2xl font-bold text-primary-dark mb-4">
        Conectando con Google...
      </h1>
      <p className="text-secondary-dark">
        Por favor espera mientras conectamos tu cuenta.
      </p>
    </div>
  );
};

export default OAuthCallback;
