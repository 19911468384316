// /src/pages/ConnectGoogle.jsx
import React, { useState, useEffect } from "react";
import axiosClient from "../api/axiosClient"; // Axios configurado

const ConnectGoogle = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Variable de entorno
  const redirectUri = `${window.location.origin}/oauth-callback`; // URI de redirección
  // const oauthUri = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=https://www.googleapis.com/auth/business.manage&access_type=offline&prompt=consent`;
  const oauthUri = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=https://www.googleapis.com/auth/business.manage&access_type=offline&prompt=consent`;

  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga

  // Comprobar si ya está conectado al cargar el componente
  useEffect(() => {
    const checkConnection = async () => {
      try {
        setIsLoading(true); // Inicia la carga
        const response = await axiosClient.get("/oauthgoogleb");
        // console.log("Conexión comprobada:", response);
        if (response && response.connected !== undefined) {
          setIsConnected(response.connected);
        } else {
          console.error("Respuesta inesperada:", response);
        }
      } catch (error) {
        console.error("Error comprobando conexión:", error);
      } finally {
        setIsLoading(false); // Finaliza la carga
      }
    };

    checkConnection();
  }, []);

  const handleConnectGoogle = () => {
    window.location.href = oauthUri; // Redirige al usuario al OAuth Consent Screen
  };

  return (
    <div className="bg-neutral-white rounded-lg shadow-card p-6">
      <h1 className="font-heading text-h2 text-primary-dark mb-4">Conectar Google</h1>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary border-opacity-50"></div>
        </div>
      ) : isConnected ? (
        <div className="flex items-center">
          <span className="text-state-success text-xl mr-2">✓</span>
          <p className="text-neutral-darkGray">
            Google Business está conectado correctamente.
          </p>
        </div>
      ) : (
        <>
          <p className="text-neutral-darkGray">
            Conecta tu cuenta de Google para gestionar tu perfil de Google Business.
          </p>
          <button
            onClick={handleConnectGoogle}
            className="mt-4 bg-gradient-to-r from-primary-light to-primary-dark text-neutral-white py-2 px-4 rounded-md hover:opacity-90 transition duration-300"
          >
            Conectar con Google
          </button>
        </>
      )}
    </div>
  );
};

export default ConnectGoogle;
