import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../api/axiosClient";

const RequireGoogleConnection = ({ children }) => {
  const [isConnected, setIsConnected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkGoogleConnection = async () => {
      try {
        setIsLoading(true);
        const response = await axiosClient.get("/oauthgoogleb");
        setIsConnected(response.connected);
      } catch (error) {
        console.error("Error comprobando conexión de Google:", error);
        setIsConnected(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkGoogleConnection();
  }, []);

  if (isLoading) {
    // Spinner mientras se verifica la conexión
    return (
      <div className="flex justify-center items-center h-screen bg-neutral-lightGray">
        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary"></div>
      </div>
    );
  }

  if (!isConnected) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-neutral-lightGray">
        <h1 className="font-heading text-h2 text-primary-dark mb-4">
          Necesitas conectar tu perfil de Google Business
        </h1>
        <p className="text-base text-neutral-darkGray mb-6 text-center">
          Para usar las funciones de la aplicación, primero conecta tu cuenta de Google Business.
        </p>
        <button
          onClick={() => navigate("/connect-google")}
          className="bg-primary text-neutral-white px-6 py-3 rounded-md hover:bg-primary-dark transition-colors font-medium"
        >
          Conectar Google Business
        </button>
      </div>
    );
  }

  // Si está conectado, renderiza los hijos (página solicitada)
  return children;
};

export default RequireGoogleConnection;
