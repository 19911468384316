import React from "react";
import Sidebar from "./Sidebar";

/**
 * Layout
 * @description
 * Layout principal que contiene el sidebar y la sección de contenido.
 *
 * @param {React.ReactNode} children - Contenido que se renderiza en la sección principal.
 */
const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex bg-neutral-lightGray text-neutral-darkGray">
      <Sidebar />
      <main className="flex-1  bg-neutral-white rounded-l-lg shadow-card">
        {children}
      </main>
    </div>
  );
};

export default Layout;
