// ChatApp.jsx

import React, { useState, useEffect, useRef } from "react";
import axiosClient from "../api/axiosClient";

/**
 * ChatApp Component
 *
 * - Obtiene todos los mensajes (endpoint '/chats').
 * - Agrupa los mensajes por chat (contactId) y utiliza contactName para mostrar el nombre.
 * - Permite filtrar chats mediante un buscador.
 * - Al seleccionar un chat, muestra sus mensajes ordenados de forma ascendente.
 * - Los mensajes enviados se alinean a la derecha y los recibidos a la izquierda.
 * - Se inserta un separador con la fecha (en español, sin año) cada vez que cambia el día.
 * - En los mensajes enviados se muestran ticks según su status.
 * - Si la conversación está activa (menos de 24h desde la primera respuesta del cliente),
 *   se puede enviar un mensaje de texto al presionar el botón de enviar. La petición se realiza
 *   a través de axiosClient al endpoint '/send-text-message'.
 */
const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [newMessage, setNewMessage] = useState("");
  // Estado para controlar el envío del mensaje
  const [sending, setSending] = useState(false);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axiosClient.get("/chats");
        console.log(response);
        setMessages(response.messages || []);
      } catch (err) {
        console.error("Error fetching chats:", err);
        setError(
          err.response?.data?.error ||
            err.message ||
            "Error fetching chats"
        );
      } finally {
        setLoading(false);
      }
    };
    fetchChats();
  }, []);

  // Agrupar mensajes por contactId
  const chats = messages.reduce((acc, msg) => {
    const contactId = msg.contactId;
    if (!acc[contactId]) {
      acc[contactId] = [];
    }
    acc[contactId].push(msg);
    return acc;
  }, {});

  // Crear lista de chats con información del último mensaje y nombre del contacto
  const chatList = Object.keys(chats)
    .map((contactId) => {
      const msgs = chats[contactId];
      const lastMsg = msgs.reduce((prev, curr) =>
        new Date(curr.timestamp) > new Date(prev.timestamp) ? curr : prev
      );
      return {
        contactId,
        name: lastMsg.contactName || "Contacto Eliminado",
        lastMessage: lastMsg,
        messages: msgs,
      };
    })
    .sort(
      (a, b) =>
        new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp)
    );

  // Seleccionar el primer chat si ninguno está seleccionado
  useEffect(() => {
    if (!selectedChat && chatList.length > 0) {
      setSelectedChat(chatList[0].contactId);
    }
  }, [chatList, selectedChat]);

  // Filtrar chats según el término de búsqueda
  const filteredChatList = chatList.filter((chat) => {
    const lowerQuery = searchQuery.toLowerCase();
    const nameMatch = chat.name.toLowerCase().includes(lowerQuery);
    const messageMatch = chat.messages.some((message) =>
      message.content.toLowerCase().includes(lowerQuery)
    );
    return nameMatch || messageMatch;
  });

  // Obtener y ordenar los mensajes del chat seleccionado (orden ascendente)
  const selectedMessages = selectedChat
    ? (chats[selectedChat] || []).sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      )
    : [];

  // Nombre del chat seleccionado para el header
  const selectedChatName =
    chatList.find((chat) => chat.contactId === selectedChat)?.name ||
    selectedChat;

  // Calcular si la conversación está activa (menos de 24h desde la primera respuesta del cliente)
  const conversationActive = (() => {
    const clientMessages = selectedMessages.filter(
      (msg) => msg.direction === "received"
    );
    if (clientMessages.length === 0) return false;
    const firstClientMsg = clientMessages.reduce((min, curr) =>
      new Date(curr.timestamp) < new Date(min.timestamp) ? curr : min,
      clientMessages[0]
    );
    const diff = Date.now() - new Date(firstClientMsg.timestamp).getTime();
    return diff < 24 * 60 * 60 * 1000;
  })();

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMessages]);

  // Función para enviar el mensaje de texto al backend
  const handleSendMessage = async () => {
    if (!conversationActive) {
      alert(
        "La conversación ha expirado (más de 24h). Envía un template para iniciar una nueva conversación."
      );
      return;
    }
    if (!newMessage.trim()) return;
    setSending(true);
    try {
      const payload = {
        contactId: selectedChat,
        content: newMessage.trim(),
      };
      // La axiosClient ya incluye el token en el header y de ahí se extraerá el userId en la lambda
      const response = await axiosClient.post("/send-message", payload);
      console.log("Mensaje enviado:", response);
      // Actualización optimista: agregamos el nuevo mensaje a la UI
      const newMsg = {
        id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
        contactId: selectedChat,
        contactName: selectedChatName,
        type: "text",
        content: newMessage.trim(),
        status: "sent",
        timestamp: new Date().toISOString(),
        direction: "sent",
        phone: "system",
      };
      setMessages((prev) => [...prev, newMsg]);
      setNewMessage("");
    } catch (error) {
      console.error("Error enviando el mensaje:", error);
      alert("Error al enviar el mensaje.");
    } finally {
      setSending(false);
    }
  };

  if (loading) {
    return <div className="text-center p-4">Cargando chats...</div>;
  }

  if (error) {
    return (
      <div className="text-center p-4 text-red-500">Error: {error}</div>
    );
  }

  return (
    <div className="flex h-screen bg-neutral-white font-sans">
      {/* Panel izquierdo: Lista de chats */}
      <div className="w-1/3 flex flex-col border-r border-neutral-lightGray bg-neutral-lightGray">
        <div className="p-4 bg-neutral-white h-[70px]">
          <div className="flex items-center bg-neutral-lightGray rounded-xl px-4 py-2">
            <svg
              className="w-5 h-5 text-neutral-mediumGray"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <input
              type="text"
              placeholder="Buscar chats"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="ml-2 bg-transparent outline-none w-full text-neutral-darkGray placeholder-neutral-mediumGray"
            />
          </div>
        </div>
        <div
          className="flex-1 overflow-y-auto"
          style={{ height: "calc(100vh - 70px)" }}
        >
          {filteredChatList.length === 0 ? (
            <div className="p-4 text-neutral-mediumGray text-center">
              {searchQuery ? "No se encontraron chats" : "No hay chats"}
            </div>
          ) : (
            filteredChatList.map((chat) => (
              <div
                key={chat.contactId}
                className={`flex items-center p-3 cursor-pointer hover:bg-neutral-lightGray/50 ${
                  selectedChat === chat.contactId
                    ? "bg-primary-light"
                    : "bg-neutral-white"
                } border-b border-neutral-lightGray`}
                onClick={() => setSelectedChat(chat.contactId)}
              >
                <div className="flex-1 min-w-0">
                  <div className="flex justify-between items-center mb-1">
                    <h3 className="text-neutral-black font-semibold truncate">
                      {chat.name}
                    </h3>
                    <span className="text-xs text-neutral-mediumGray">
                      {new Date(chat.lastMessage.timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-sm text-neutral-mediumGray truncate">
                      {chat.lastMessage.type === "image"
                        ? chat.lastMessage.caption && chat.lastMessage.caption.trim() !== ""
                          ? chat.lastMessage.caption.substring(0, 30)
                          : "Imagen"
                        : chat.lastMessage.content.substring(0, 30)}
                      {chat.lastMessage.type !== "image" &&
                        chat.lastMessage.content.length > 30 &&
                        "…"}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Panel derecho: Ventana de chat */}
      <div className="w-2/3 flex flex-col bg-[#ECE5DD]">
        {/* Header */}
        <div className="p-4 bg-neutral-white border-b border-neutral-lightGray flex items-center h-[70px]">
          <div className="flex-1">
            <h2 className="text-neutral-black font-semibold">
              Chat con: {selectedChatName}
            </h2>
          </div>
        </div>

        {/* Área de mensajes */}
        <div
          className="flex-1 overflow-y-auto p-4 space-y-2"
          style={{
            height: "calc(100vh - 140px)",
            backgroundImage:
              "url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)",
          }}
        >
          {selectedMessages.length === 0 ? (
            <div className="text-center text-neutral-mediumGray py-8">
              No hay mensajes en este chat.
            </div>
          ) : (
            selectedMessages.map((message, index) => {
              // Separador de fecha: Formatear en español (día y mes, sin año)
              const currentDate = new Date(message.timestamp).toLocaleDateString("es-ES", {
                day: "numeric",
                month: "long",
              });
              const previousDate =
                index > 0
                  ? new Date(selectedMessages[index - 1].timestamp).toLocaleDateString("es-ES", {
                      day: "numeric",
                      month: "long",
                    })
                  : null;
              const showDateSeparator = index === 0 || currentDate !== previousDate;

              return (
                <React.Fragment key={message.id}>
                  {showDateSeparator && (
                    <div className="flex justify-center my-4">
                      <div className="bg-neutral-white text-neutral-darkGray px-4 py-1 rounded-full shadow">
                        {currentDate}
                      </div>
                    </div>
                  )}
                  <div className={`flex ${message.direction === "sent" ? "justify-end" : "justify-start"}`}>
                    <div
                      className={`max-w-md p-3 rounded-lg shadow-card ${
                        message.direction === "sent"
                          ? "bg-primary-light text-neutral-black rounded-br-none"
                          : "bg-neutral-white rounded-bl-none"
                      }`}
                      style={{ borderRadius: "1.125rem" }}
                    >
                      {(message.type === "template" || message.type === "image") && message.mediaUrl && (
                        <img
                          src={message.mediaUrl}
                          alt="Media"
                          className="mb-2 w-full rounded-lg"
                        />
                      )}
                      <p className="text-sm text-natural-black">{message.content}</p>
                      <div className="flex items-center justify-end mt-1 space-x-1">
                        <span className="text-xs text-neutral-mediumGray">
                          {new Date(message.timestamp).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                        {message.direction === "sent" && (
                          <>
                            {message.status === "sent" && (
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 text-neutral-mediumGray" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" />
                              </svg>
                            )}
                            {(message.status === "delivered" || message.status === "read") && (
                              <div className="flex items-center space-x-0.5">
                                <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${message.status === "read" ? "text-blue-600" : "text-neutral-mediumGray"}`} viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`w-4 h-4 ${message.status === "read" ? "text-blue-600" : "text-neutral-mediumGray"}`} viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" clipRule="evenodd" />
                                </svg>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
          <div ref={messagesEndRef} />
        </div> 
    
        {/* Footer: Entrada de mensajes */}
          <div className="p-4 bg-neutral-lightGray/30 h-[70px]">
                  <div className="flex items-center h-full">
                    <div className="flex-1 bg-neutral-white rounded-full px-4 py-2 flex items-center h-12">
                      <button className="text-neutral-mediumGray hover:text-neutral-darkGray p-1">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                      <input
                        type="text"
                        placeholder="Escribe un mensaje"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="flex-1 mx-2 bg-transparent outline-none text-neutral-darkGray placeholder-neutral-mediumGray"
                      />
                    </div>
                    <button
                      onClick={handleSendMessage}
                      className={`ml-2 p-2 rounded-full h-12 w-12 flex items-center justify-center ${
                        conversationActive && !sending
                          ? "bg-primary-DEFAULT hover:bg-primary-dark"
                          : "bg-neutral-mediumGray cursor-not-allowed"
                      }`}
                      disabled={!conversationActive || sending}
                      title={
                        !conversationActive
                          ? "La conversación ha expirado (más de 24h). Envía un template para iniciar una nueva conversación."
                          : ""
                      }
                    >
                      {sending ? (
                        <svg className="animate-spin w-6 h-6" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                        </svg>
                      ) : (
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                        </svg>
                      )}
                    </button>
                  </div>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
