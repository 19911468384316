// App.jsx
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Páginas
import ConnectGoogle from "./pages/ConnectGoogle";
import Dashboard from "./pages/Dashboard";
import Contacts from "./pages/Contacts";
import ChatApp from "./pages/ChatApp";
import Reviews from "./pages/Reviews";
import OAuthCallback from "./pages/OAuthCallback";
import Automations from "./pages/Automations";
import Feedback from "./pages/Feedback";
import Login from "./pages/Login";

// Componentes
import Layout from "./components/Layout";
import RequireGoogleConnection from "./components/RequireGoogleConnection";

/**
 * Función helper para verificar si un token JWT ha caducado.
 * Se asume que el token tiene un campo "exp" (expiración) en segundos.
 * @param {string} token - JWT almacenado en localStorage.
 * @returns {boolean} - true si el token ha caducado, false en caso contrario.
 */
function isTokenExpired(token) {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
}

/**
 * RequireAuth
 * Componente de orden superior (HOC) que verifica si el token JWT existe
 * y no ha caducado. En caso de caducidad, muestra un mensaje y redirige a /login.
 */
function RequireAuth({ children }) {
  const token = localStorage.getItem("token");
  if (!token || isTokenExpired(token)) {
    if (token && isTokenExpired(token)) {
      alert("La sesión ha caducado. Por favor, inicia sesión de nuevo.");
      localStorage.removeItem("token");
    }
    return <Navigate to="/login" replace />;
  }
  return children;
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Ruta de Login */}
        <Route path="/login" element={<Login />} />

        {/* Rutas protegidas por autenticación */}
        <Route
          path="*"
          element={
            <RequireAuth>
              <Layout>
                <Routes>
                  {/* Página para conectar Google Business */}
                  <Route path="/connect-google" element={<ConnectGoogle />} />

                  {/* Páginas protegidas por conexión de Google */}
                  <Route
                    path="/dashboard"
                    element={
                      <RequireGoogleConnection>
                        <Dashboard />
                      </RequireGoogleConnection>
                    }
                  />
                  <Route
                    path="/contacts"
                    element={
                      <RequireGoogleConnection>
                        <Contacts />
                      </RequireGoogleConnection>
                    }
                  />
                  {/* Ruta chats */}
                  <Route
                    path="/chats"
                    element={
                      <RequireGoogleConnection>
                        <ChatApp />
                      </RequireGoogleConnection>
                    }
                  />
                  {/* Ruta Automatizaciones */}
                  <Route
                    path="/automations"
                    element={
                      <RequireGoogleConnection>
                        <Automations />
                      </RequireGoogleConnection>
                    }
                  />
                  <Route
                    path="/reviews"
                    element={
                      <RequireGoogleConnection>
                        <Reviews />
                      </RequireGoogleConnection>
                    }
                  />
                  <Route
                    path="/feedback"
                    element={
                      <RequireGoogleConnection>
                        <Feedback />
                      </RequireGoogleConnection>
                    }
                  />
                  <Route path="/oauth-callback" element={<OAuthCallback />} />
                  <Route path="*" element={<Dashboard />} />
                </Routes>
              </Layout>
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
