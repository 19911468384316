import React, { useState, useEffect } from "react";
import axios from "../api/axiosClient";
import { useNavigate } from "react-router-dom";

/**
 * Feedback Page Component
 *
 * Fetches and displays feedback left by clients.
 * The component retrieves the feedback from the backend using the axiosClient.
 * If the user is not authenticated (no token), it redirects to the login page.
 */
const Feedback = () => {
  // State management for feedback data, loading status, and errors.
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Feedbacks por página
  const navigate = useNavigate();

  // Effect hook to fetch feedback when the component mounts.
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    axios
      .get("/feedback")
      .then((data) => {
        setFeedbacks(data.feedback || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching feedback:", err);
        setError("Error fetching feedback");
        setLoading(false);
      });
  }, [navigate]);

  // Filtrar por calificación
  const filteredFeedbacks =
    filter === "all"
      ? feedbacks
      : feedbacks.filter((item) => item.rating.N === filter);
  // Filtrar por búsqueda en feedback
  const searchedFeedbacks = filteredFeedbacks.filter((item) =>
    item.feedback.S.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Ordenar por fecha
  const sortedFeedbacks = [...searchedFeedbacks].sort((a, b) => {
    const dateA = new Date(a.timestamp.S);
    const dateB = new Date(b.timestamp.S);
    return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
  });
  // Paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFeedbacks = sortedFeedbacks.slice(indexOfFirstItem, indexOfLastItem);
  // Cambiar página
  const totalPages = Math.ceil(sortedFeedbacks.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="min-h-screen bg-neutral-lightGray p-6">
      <div className="max-w-4xl mx-auto bg-neutral-white p-6 rounded-lg shadow-card">
        <h1 className="font-heading text-h2 text-primary-dark mb-6">
          Feedback interno enviado por los clientes
        </h1>
        {/* Barra de búsqueda */}
        <input
          type="text"
          placeholder="Buscar feedback..."
          className="w-full p-2 mb-4 border border-neutral-mediumGray rounded-lg bg-neutral-white"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {/* Filtros y ordenamiento */}
        <div className="flex justify-between items-center mb-4">
          <div>
            <p className="text-neutral-darkGray">Filtrar por calificación:</p>
            <select
              className="border border-neutral-mediumGray p-2 rounded-lg bg-neutral-white"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">Todos</option>
              <option value="5">⭐⭐⭐⭐⭐ (5)</option>
              <option value="4">⭐⭐⭐⭐ (4)</option>
              <option value="3">⭐⭐⭐ (3)</option>
              <option value="2">⭐⭐ (2)</option>
              <option value="1">⭐ (1)</option>
            </select>
          </div>
          <div>
            <p className="text-neutral-darkGray">Ordenar por Fecha:</p>
            <select
              className="border border-neutral-mediumGray p-2 rounded-lg bg-neutral-white"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="desc">Más recientes primero</option>
              <option value="asc">Más antiguas primero</option>
            </select>
          </div>
        </div>
        {/* Contenido */}
        {loading ? (
          <p className="text-neutral-darkGray">Cargando feedback...</p>
        ) : error ? (
          <p className="text-state-error">{error}</p>
        ) : currentFeedbacks.length === 0 ? (
          <p className="text-neutral-mediumGray">No hay feedback disponible.</p>
        ) : (
          <div className="space-y-4">
            {currentFeedbacks.map((item) => (
              <div
                key={item.feedbackId.S}
                className="p-4 bg-neutral-white rounded-lg shadow-card"
              >
                <p className="text-lg text-neutral-darkGray">
                  <strong>Feedback:</strong> {item.feedback.S}
                </p>
                <p className="text-primary-dark">
                  <strong>Rating:</strong> {"⭐".repeat(item.rating.N)}
                </p>
                <p className="text-neutral-mediumGray text-sm">
                  <strong>Fecha:</strong> {new Date(item.timestamp.S).toLocaleString()}
                </p>
                {item.contactId && item.contactId.S && (
                  <p className="text-accent-turquoise">
                    <strong>Contacto:</strong> {item.contactId.S}
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
        {/* Paginación */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-6 space-x-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === i + 1
                    ? "bg-primary text-neutral-white"
                    : "bg-neutral-lightGray text-neutral-darkGray"
                }`}
                onClick={() => paginate(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
