import React, { useState, useEffect } from "react";
import axiosClient from "../api/axiosClient";
import DripForm from "../components/DripForm"; // Formulario para configurar Drip

/**
 * Automatizaciones
 * @description
 * Página que lista las automatizaciones y permite crear nuevas.
 */
const Automations = () => {
  const [automations, setAutomations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Cargar automatizaciones al montar
  useEffect(() => {
    fetchAutomations();
  }, []);

  /**
   * fetchAutomations
   * @description Obtiene todas las automatizaciones para el usuario logueado
   */
  const fetchAutomations = async () => {
    try {
      setIsLoading(true);
      // GET /automations - asumiendo que el token en axiosClient maneja userId
      const response = await axiosClient.get("/automations");
      setAutomations(response); // Ajusta si tu respuesta viene envuelta en un objeto data
    } catch (error) {
      console.error("Error al obtener automatizaciones:", error);
      alert("Error al obtener automatizaciones.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * handleCreateAutomation
   * @description Envía la nueva automatización al backend
   */
  const handleCreateAutomation = async (dripConfig) => {
    try {
      // console.log("Creando automatización:", dripConfig);
      const response = await axiosClient.post("/automations", { dripConfig });
      // console.log("Automatización creada:", response);
      alert("Automatización creada exitosamente");
      setShowForm(false);
      // Refrescar la lista
      fetchAutomations();
    } catch (error) {
      console.error("Error al crear automatización:", error);
      alert("Error al crear la automatización.");
    }
  };

  return (
    <div className="p-4">
      <h1 className="font-heading text-h2 text-neutral-black mb-4">Automatizaciones</h1>

      <button
        onClick={() => setShowForm(!showForm)}
        className="bg-primary hover:bg-primary-dark text-neutral-white font-bold py-2 px-4 rounded-md transition duration-300 mb-4"
      >
        {showForm ? "Cancelar" : "Crear Nueva Automatización"}
      </button>

      {showForm && (
        <div className="mb-6">
          <DripForm onSave={(dripConfig) => handleCreateAutomation(dripConfig)} />
        </div>
      )}

      {isLoading ? (
        <div className="text-neutral-darkGray">Loading...</div>
      ) : (
        <div>
          {automations.length === 0 ? (
            <p className="text-neutral-darkGray">No hay automatizaciones creadas.</p>
          ) : (
            <ul className="bg-neutral-white shadow-card rounded-lg divide-y divide-neutral-mediumGray">
              {automations.map((auto, idx) => (
                <li key={idx} className="p-4">
                  <p className="font-semibold text-neutral-black">
                    {auto.automationName || `Automatización #${idx + 1}`}
                  </p>
                  <p className="text-sm text-neutral-darkGray">Estado: {auto.status}</p>
                  {/* Muestra cualquier info extra del dripConfig */}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Automations;
