// Login.jsx
import React, { useState } from "react";
import axiosClient from "../api/axiosClient"; // Configuración centralizada de Axios
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log("Version 1.0.2");

  /**
   * handleSubmit
   * @description Envía las credenciales al backend (Lambda) y procesa la respuesta.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // Validación básica de campos obligatorios
    if (!username || !password) {
      setError("El username y la password son obligatorios");
      setLoading(false);
      return;
    }

    try {
      // Realizar la llamada POST al endpoint de login
      const response = await axiosClient.post("/login", { username, password });
      // console.log("response:", response);
      // La respuesta de la Lambda tiene la forma:
      // {
      //   statusCode: 200,
      //   body: "{\"message\":\"Login exitoso\",\"token\":\"...\"}"
      // }
      const { statusCode, body } = response;
      // Parseamos el 'body' para acceder a sus propiedades
      const parsedBody = JSON.parse(body);

      // Verificar si el login fue exitoso basándonos en el statusCode de la Lambda
      if (statusCode === 200) {
        const { token } = parsedBody;
        // Almacenar el token JWT de manera segura en el localStorage
        localStorage.setItem("token", token);
        // Redirigir al usuario al dashboard
        navigate("/dashboard");
      } else {
        // Mostrar el mensaje de error retornado por la Lambda (p. ej., credenciales inválidas)
        setError(parsedBody.message || "Error en el login");
      }
    } catch (err) {
      console.error("Error en el login:", err);
      // Mensaje genérico en caso de error en la conexión o error inesperado
      setError("Error en la conexión al servidor");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-neutral-lightGray">
      <div className="bg-neutral-white p-8 rounded-lg shadow-card max-w-md w-full">
        <h1 className="font-heading text-h2 text-primary-dark mb-6 text-center">
          Iniciar Sesión
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {error && (
            <p className="text-state-error text-sm font-semibold p-2 rounded bg-state-error/10">
              {error}
            </p>
          )}
          <div>
            <label className="block text-neutral-darkGray font-medium mb-2">
              Username
            </label>
            <input
              className="border border-neutral-mediumGray p-3 w-full rounded focus:outline-none focus:ring-2 focus:ring-primary-light"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Tu usuario"
            />
          </div>
          <div>
            <label className="block text-neutral-darkGray font-medium mb-2">
              Password
            </label>
            <input
              className="border border-neutral-mediumGray p-3 w-full rounded focus:outline-none focus:ring-2 focus:ring-primary-light"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Tu contraseña"
            />
          </div>
          <button
            type="submit"
            className={`w-full text-neutral-white p-3 rounded transition duration-300 ${
              loading
                ? "bg-neutral-mediumGray cursor-not-allowed"
                : "bg-primary hover:bg-primary-dark"
            }`}
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center space-x-2">
                <span className="loader w-4 h-4 border-2 border-neutral-white rounded-full animate-spin"></span>
                <span>Procesando...</span>
              </span>
            ) : (
              "Acceder"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
