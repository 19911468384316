import axios from "axios";

/**
 * axiosClient
 * Configuración de Axios para la comunicación con el API Gateway.
 * Incluye interceptores para manejo de errores y se podrá extender con
 * encabezados de autenticación, tokens, etc.
 */
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 10000,
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Obtén el token del almacenamiento local
    // console.log("token enviado: ", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor de respuesta
axiosClient.interceptors.response.use(
  (response) => {
    return response.data; // Ajusta según tu respuesta
  },
  (error) => Promise.reject(error)
);

export default axiosClient;
