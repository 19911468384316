// src/pages/Dashboard.jsx
import React, { useEffect, useState, useMemo } from "react";
import axiosClient from "../api/axiosClient";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
} from "recharts";

// ----- Helpers de formato -----
const formatDateISO = (date) => {
  const d = new Date(date);
  // Se usa el horario local para evitar el desfase que produce toISOString()
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const subtractMonths = (date, months) => {
  const d = new Date(date);
  d.setMonth(d.getMonth() - months);
  return d;
};

// Add new helper functions for date handling
const getFirstDayOfCurrentMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
};

const getLastDayOfCurrentMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0);
};

// ----- Componente RatingGauge -----
const RatingGauge = ({ averageRating, currentAverage }) => {
  // console.log(
  //   "averageRating:",
  //   averageRating,
  //   "currentAverage:",
  //   currentAverage
  // );

  const normalizeRating = (rating) => Math.min(Math.max(rating, 0), 5);
  const normalizedInitial = normalizeRating(averageRating);
  const normalizedCurrent = normalizeRating(currentAverage);

  const percentageInitial = normalizedInitial / 5;
  const percentageCurrent = normalizedCurrent / 5;

  const cx = 100;
  const cy = 100;
  const r = 80;
  const strokeWidth = 12;
  const totalAngle = 180;

  const degToRad = (deg) => (deg * Math.PI) / 180;

  const polarToCartesian = (cx, cy, r, angleDeg) => {
    const angleRad = degToRad(angleDeg);
    return {
      x: cx + r * Math.cos(angleRad),
      y: cy + r * Math.sin(angleRad),
    };
  };

  // Background arc
  const bgStart = polarToCartesian(cx, cy, r, 180);
  const bgEnd = polarToCartesian(cx, cy, r, 360);
  const bgPath = `M ${bgStart.x} ${bgStart.y} A ${r} ${r} 0 1 1 ${bgEnd.x} ${bgEnd.y}`;

  // Initial rating arc
  const initialAngle = totalAngle * percentageInitial;
  const initialEnd = polarToCartesian(cx, cy, r, 180 + initialAngle);
  const initialArcFlag = initialAngle > 180 ? "1" : "0";
  const initialPath = `M ${bgStart.x} ${bgStart.y} A ${r} ${r} 0 ${initialArcFlag} 1 ${initialEnd.x} ${initialEnd.y}`;

  // Current rating arc (mismo radio que el inicial)
  const currentAngle = totalAngle * percentageCurrent;
  const currentEnd = polarToCartesian(cx, cy, r, 180 + currentAngle);
  const currentArcFlag = currentAngle > 180 ? "1" : "0";
  const currentPath = `M ${bgStart.x} ${bgStart.y} A ${r} ${r} 0 ${currentArcFlag} 1 ${currentEnd.x} ${currentEnd.y}`;

  // Determine if there's an improvement
  const hasImproved = currentAverage > averageRating;
  const currentColor = hasImproved ? "#2563eb" : "#ef4444"; // blue-600 for improvement, red-500 for decline

  return (
    <svg width="200" height="150" viewBox="0 0 200 150">
      {/* Background arc */}
      <path
        d={bgPath}
        fill="none"
        stroke="#e5e5e5"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />

      {/* Current rating arc - ahora va primero para que esté debajo */}
      {hasImproved && (
        <path
          d={currentPath}
          fill="none"
          stroke={currentColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={`${strokeWidth * 0.8}, ${strokeWidth * 0.4}`}
        />
      )}

      {/* Initial rating arc - ahora va después para que esté encima */}
      <path
        d={initialPath}
        fill="none"
        stroke="#4CAF50"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />

      {/* Text values */}
      <text
        x="100"
        y="90"
        textAnchor="middle"
        fontSize="24"
        fill="#333"
        fontWeight="bold"
      >
        {normalizedCurrent.toFixed(2)}
      </text>

      <text x="100" y="115" textAnchor="middle" fontSize="14" fill="#666">
        de 5 {hasImproved ? "↑" : ""}
      </text>

      {/* Legend */}
      <g transform="translate(50, 140)" fontSize="10">
        <circle cx="0" cy="0" r="4" fill="#4CAF50" />
        <text x="8" y="3" fill="#666">
          Inicial {normalizedInitial.toFixed(2)}
        </text>

        {hasImproved && (
          <>
            <circle cx="80" cy="0" r="4" fill={currentColor} />
            <text x="88" y="3" fill="#666">
              Actual {normalizedCurrent.toFixed(2)}
            </text>
          </>
        )}
      </g>
    </svg>
  );
};

// ----- Componente TrendIndicator -----
const TrendIndicator = ({ value }) => (
  <div className="flex items-center">
    <svg
      className={`w-4 h-4 ${
        value >= 0 ? "text-state-success" : "text-state-error"
      }`}
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      {/*
          Para valor >= 0, se muestra una flecha hacia arriba:
          "M10 5l5 5H5l5-5z" => Comienza en (10,5), dibuja una línea a (15,10),
          luego horizontal a (5,10) y vuelve al punto de inicio, formando un triángulo apuntando hacia arriba.

          Para valor < 0, se muestra una flecha hacia abajo:
          "M10 15l-5-5h10l-5 5z" => Comienza en (10,15), dibuja una línea a (5,10),
          luego horizontal a (15,10) y cierra el triángulo, apuntando hacia abajo.
      */}
      <path d={value >= 0 ? "M10 5l5 5H5l5-5z" : "M10 15l-5-5h10l-5 5z"} />
    </svg>
    <span
      className={`ml-1 ${
        value >= 0 ? "text-state-success" : "text-state-error"
      }`}
    >
      {Math.abs(value)}
    </span>
  </div>
);

// ----- Helper para formatear mes -----
const formatMonth = (monthStr) => {
  const [year, month] = monthStr.split("-");
  const date = new Date(year, month - 1);
  return date.toLocaleString("es-ES", { month: "long", year: "numeric" });
};

// ----- Opciones de métricas (con info) -----
const metricOptions = [
  {
    value: "totalImpressions",
    label: "Total Impresiones (Perfil)",
    info: "Suma de: Impresiones en Google Maps y en la Búsqueda de Google (tanto en escritorio como en móvil). Se registra solo una vez por usuario único al día.",
  },
  {
    value: "BUSINESS_FOOD_ORDERS",
    label: "Pedidos de Comida",
    info: "Indica la cantidad de pedidos de comida recibidos desde el Perfil de Negocio.",
  },
  {
    value: "BUSINESS_FOOD_MENU_CLICKS",
    label: "Clics en Menú de Comida",
    info: "Indica la cantidad de clics para ver el contenido del menú o interactuar con él. Los clics múltiples de un mismo usuario en un día se registran como 1.",
  },
  {
    value: "BUSINESS_DIRECTION_REQUESTS",
    label: "Solicitudes de Dirección",
    info: "Cantidad de veces que se solicitó instrucciones para llegar a la empresa.",
  },
  {
    value: "CALL_CLICKS",
    label: "Clics en Llamada",
    info: "Número de veces que se hizo clic en el botón de llamada del Perfil de Negocio.",
  },
  {
    value: "WEBSITE_CLICKS",
    label: "Clics en Sitio Web",
    info: "Número de veces que se hizo clic en el sitio web del Perfil de Negocio.",
  },
  {
    value: "BUSINESS_BOOKINGS",
    label: "Reservas",
    info: "Cantidad de reservas realizadas desde el Perfil de Negocio a través de Reserva con Google.",
  },
];

const Dashboard = () => {
  const [stats, setStats] = useState(null);
  const [perfAgg, setPerfAgg] = useState(null);
  const [error, setError] = useState("");
  const [perfError, setPerfError] = useState("");
  const [selectedMetric, setSelectedMetric] = useState("");
  const [totalIncrease, setTotalIncrease] = useState(0);
  const [trafficStartDate, setTrafficStartDate] = useState(
    formatDateISO(getFirstDayOfCurrentMonth())
  );
  const [trafficEndDate, setTrafficEndDate] = useState(
    formatDateISO(getLastDayOfCurrentMonth())
  );

  const handleCurrentMonth = () => {
    setTrafficStartDate(formatDateISO(getFirstDayOfCurrentMonth()));
    setTrafficEndDate(formatDateISO(getLastDayOfCurrentMonth()));
  };

  // Carga inicial de datos
  useEffect(() => {
    fetchDashboardData();
    fetchPerformanceMetrics();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await axiosClient.get("/dashboard");
      // console.log("Dashboard data:", response);
      setStats(response);
      setTotalIncrease(response.totalReviewCount - response.initialReviewCount);
    } catch (err) {
      setError(err.response?.data?.message || "Error cargando estadísticas");
    }
  };

  const fetchPerformanceMetrics = async () => {
    try {
      const response = await axiosClient.get("/performance-metrics");
      setPerfAgg(response);
    } catch (err) {
      setPerfError(
        err.response?.data?.message || "Error cargando métricas de performance"
      );
    }
  };

  useEffect(() => {
    // console.log("perfAgg state actualizado:", perfAgg);
  }, [perfAgg]);

  const monthlyChartData = useMemo(() => {
    if (!perfAgg || !perfAgg.monthlyAggregates || !selectedMetric) return [];
    return perfAgg.monthlyAggregates.map(({ month, aggregates }) => {
      let value;
      if (selectedMetric === "totalImpressions") {
        value =
          (aggregates.BUSINESS_IMPRESSIONS_DESKTOP_MAPS || 0) +
          (aggregates.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH || 0) +
          (aggregates.BUSINESS_IMPRESSIONS_MOBILE_MAPS || 0) +
          (aggregates.BUSINESS_IMPRESSIONS_MOBILE_SEARCH || 0);
      } else {
        value = aggregates[selectedMetric] || 0;
      }
      return { month, value };
    });
  }, [perfAgg, selectedMetric]);

  const currentMonthSummary = useMemo(() => {
    if (monthlyChartData.length < 1) return null;
    const last = monthlyChartData[monthlyChartData.length - 1];
    return { current: last.value, month: formatMonth(last.month) };
  }, [monthlyChartData]);

  const filteredDailyItems = useMemo(() => {
    if (!perfAgg || !perfAgg.dailyItems) return [];
    return perfAgg.dailyItems.filter((item) => {
      const itemDate = new Date(item.metricDate);
      return (
        itemDate >= new Date(trafficStartDate) &&
        itemDate <= new Date(trafficEndDate)
      );
    });
  }, [perfAgg, trafficStartDate, trafficEndDate]);

  const trafficData = useMemo(() => {
    if (filteredDailyItems.length === 0) return [];
    const totals = {
      desktopMaps: 0,
      desktopSearch: 0,
      mobileMaps: 0,
      mobileSearch: 0,
    };
    filteredDailyItems.forEach((item) => {
      totals.desktopMaps += item.BUSINESS_IMPRESSIONS_DESKTOP_MAPS || 0;
      totals.desktopSearch += item.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH || 0;
      totals.mobileMaps += item.BUSINESS_IMPRESSIONS_MOBILE_MAPS || 0;
      totals.mobileSearch += item.BUSINESS_IMPRESSIONS_MOBILE_SEARCH || 0;
    });
    const trafficMetrics = [
      {
        key: "desktopMaps",
        name: "Maps en Ordenador",
        info: "Impresiones de la empresa en Google Maps para dispositivos de escritorio (ordenadores). Varias impresiones de un usuario único en un mismo día se registran como una sola impresión.",
      },
      {
        key: "desktopSearch",
        name: "Búsqueda en Ordenador",
        info: "Impresiones de la empresa en la Búsqueda de Google para dispositivos de escritorio (ordenadores). Varias impresiones de un usuario único en un mismo día se registran como una sola impresión.",
      },
      {
        key: "mobileMaps",
        name: "Maps Móvil",
        info: "Impresiones de la empresa en Google Maps para dispositivos móviles. Varias impresiones de un usuario único en un mismo día se registran como una sola impresión.",
      },
      {
        key: "mobileSearch",
        name: "Búsqueda Móvil",
        info: "Impresiones de la empresa en la Búsqueda de Google para dispositivos móviles. Varias impresiones de un usuario único en un mismo día se registran como una sola impresión.",
      },
    ];
    return trafficMetrics.map((metric) => ({
      name: metric.name,
      value: totals[metric.key],
      info: metric.info,
    }));
  }, [filteredDailyItems]);

  const dateRangeFriendly = useMemo(() => {
    if (trafficStartDate && trafficEndDate) {
      const start = new Date(trafficStartDate).toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      const end = new Date(trafficEndDate).toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      return `${start} - ${end}`;
    }
    return "";
  }, [trafficStartDate, trafficEndDate]);

  const availableMetrics = useMemo(() => {
    if (!perfAgg || !perfAgg.monthlyAggregates) return [];
    const aggregatesArray = perfAgg.monthlyAggregates;
    const opts = [];
    metricOptions.forEach((option) => {
      let sum = 0;
      if (option.value === "totalImpressions") {
        aggregatesArray.forEach(({ aggregates }) => {
          sum +=
            (aggregates.BUSINESS_IMPRESSIONS_DESKTOP_MAPS || 0) +
            (aggregates.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH || 0) +
            (aggregates.BUSINESS_IMPRESSIONS_MOBILE_MAPS || 0) +
            (aggregates.BUSINESS_IMPRESSIONS_MOBILE_SEARCH || 0);
        });
      } else {
        aggregatesArray.forEach(({ aggregates }) => {
          sum += aggregates[option.value] || 0;
        });
      }
      if (sum > 0) {
        opts.push(option);
      }
    });
    return opts;
  }, [perfAgg]);

  const selectedMetricLabel = useMemo(() => {
    const found = availableMetrics.find((opt) => opt.value === selectedMetric);
    return found ? found.label : "";
  }, [availableMetrics, selectedMetric]);

  useEffect(() => {
    if (availableMetrics.length > 0 && !selectedMetric) {
      setSelectedMetric(availableMetrics[0].value);
    }
  }, [availableMetrics, selectedMetric]);

  return (
    <div className="min-h-screen bg-neutral-lightGray p-6 space-y-8">
      <h1 className="font-heading text-h1 text-neutral-black mb-6">
        Dashboard
      </h1>

      {/* Estadísticas Generales */}
      {error && (
        <div className="bg-state-error text-neutral-white p-3 rounded-lg">
          {error}
        </div>
      )}
      {stats ? (
        <div className="grid gap-6 md:grid-cols-2">
          {/* Tarjeta: Valoración Promedio */}
          <div className="bg-neutral-white p-6 rounded-xl shadow-card flex flex-col items-center">
            <h2 className="font-heading text-h3 text-neutral-black mb-4">
              Valoración Promedio
            </h2>
            {/* const RatingGauge = ({ initialAverage, currentAverage })  */}
            <RatingGauge
              // initialAverageRating is a string, convert to number
              averageRating={parseFloat(stats.initialAverageRating)}
              currentAverage={stats.averageRating}
            />
          </div>
          {/* Tarjeta: Reseñas - Alternativa 1 */}
          <div className="bg-neutral-white p-6 rounded-xl shadow-card">
            <h2 className="font-heading text-h3 text-neutral-black mb-4">
              Reseñas
            </h2>
            <div className="flex justify-between items-center">
              {/* Columna Izquierda: Total de Reseñas */}
              <div className="text-center">
                <p className="text-5xl font-extrabold text-neutral-black">
                  {stats.totalReviewCount}
                </p>
                <p className="text-sm text-neutral-mediumGray">Total reseñas</p>
              </div>
              {/* Columna Derecha: Incremento */}
              <div className="text-center">
                {/* <TrendIndicator value={totalIncrease} /> */}
                <p className="text-2xl font-bold text-state-success">
                  +{totalIncrease}
                </p>
                <p className="text-sm text-neutral-darkGray">Desde el inicio</p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-xs text-neutral-darkGray">
                Última actualización:{" "}
                {new Date(stats.lastUpdated).toLocaleDateString("es-ES")}
              </p>
              <p className="text-sm text-neutral-darkGray">
                Desde: {new Date(stats.initialDate).toLocaleDateString("es-ES")}{" "}
                / Inicial: {stats.initialReviewCount}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-neutral-darkGray">
          Cargando estadísticas generales...
        </p>
      )}

      <hr className="border-t border-neutral-mediumGray my-8" />

      {/* Métricas de Performance */}
      <div className="bg-neutral-white p-6 rounded-xl shadow-card space-y-8">
        <h2 className="font-heading text-h3 text-primary-dark">
          Métricas de Performance
        </h2>
        {perfError && (
          <div className="bg-state-error text-neutral-white p-3 rounded-lg">
            {perfError}
          </div>
        )}
        {!perfAgg && !perfError && (
          <p className="text-neutral-darkGray">
            Cargando métricas de performance...
          </p>
        )}

        {/* Rango de fechas */}
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="flex flex-col">
            <label
              className="mb-1 font-semibold text-neutral-darkGray"
              htmlFor="startDate"
            >
              Fecha inicio:
            </label>
            <input
              id="startDate"
              type="date"
              value={trafficStartDate}
              onChange={(e) => setTrafficStartDate(e.target.value)}
              className="border border-neutral-mediumGray rounded-lg p-2"
            />
          </div>
          <div className="flex flex-col">
            <label
              className="mb-1 font-semibold text-neutral-darkGray"
              htmlFor="endDate"
            >
              Fecha fin:
            </label>
            <input
              id="endDate"
              type="date"
              value={trafficEndDate}
              onChange={(e) => setTrafficEndDate(e.target.value)}
              className="border border-neutral-mediumGray rounded-lg p-2"
            />
          </div>
          <button
            onClick={handleCurrentMonth}
            className="px-4 py-2 mt-6 rounded-lg font-semibold shadow-card bg-primary text-neutral-white hover:bg-primary-dark transition-colors"
          >
            Este mes
          </button>
        </div>

        {/* Distribución del Tráfico */}
        <div className="flex flex-col md:flex-row items-center gap-6 mt-4">
          {/* Gráfico Circular (mayor ancho) */}
          <div className="w-full md:w-2/3">
            <h3 className="font-heading text-h4 text-neutral-black mb-2">
              Distribución del Tráfico (rango: {dateRangeFriendly})
            </h3>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={trafficData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                  label={({ name, percent, value }) =>
                    `${name}: ${value} (${(percent * 100).toFixed(0)}%)`
                  }
                >
                  {trafficData.map((entry, index) => (
                    <Cell
                      key={index}
                      fill={
                        ["#26C6DA", "#FFC107", "#4CAF50", "#FF5722"][index % 4]
                      }
                    />
                  ))}
                </Pie>
                <Legend verticalAlign="bottom" />
                <RechartsTooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          {/* Resumen del Tráfico (menor ancho) */}
          <div className="w-full md:w-1/3">
            <h3 className="font-heading text-h4 text-neutral-black mb-2">
              Resumen del Tráfico
            </h3>
            {trafficData.map((entry) => (
              <div
                key={entry.name}
                className="flex justify-between items-center py-1 border-b last:border-b-0"
              >
                <div className="flex items-center gap-2">
                  <span className="font-medium">{entry.name}</span>
                  <span
                    title={entry.info}
                    className="w-4 h-4 flex items-center justify-center bg-neutral-lightGray rounded-full text-xs text-neutral-darkGray cursor-help"
                  >
                    i
                  </span>
                </div>
                <span className="font-bold text-neutral-black">
                  {entry.value}
                </span>
              </div>
            ))}
          </div>
        </div>

        <hr className="my-8 border-t border-neutral-mediumGray" />

        {/* Evolución Mensual */}
        {monthlyChartData.length > 0 && currentMonthSummary && (
          <div>
            <h3 className="font-heading text-h4 text-neutral-black mb-2">
              Evolución Mensual de{" "}
              {selectedMetric === "totalImpressions"
                ? "Total Impresiones (Perfil)"
                : selectedMetricLabel}
            </h3>
            <div className="mb-4 p-4 bg-neutral-lightGray rounded-xl">
              <p className="font-bold text-h3 text-neutral-black">
                {currentMonthSummary.current}{" "}
                {selectedMetric === "totalImpressions"
                  ? "impresiones"
                  : selectedMetricLabel}{" "}
                en {currentMonthSummary.month}
              </p>
            </div>
            {availableMetrics.length > 0 && (
              <div className="flex flex-wrap gap-2 mb-4">
                {availableMetrics.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => setSelectedMetric(option.value)}
                    className={`px-4 py-2 rounded-lg font-semibold shadow-card flex items-center gap-1 ${
                      selectedMetric === option.value
                        ? "bg-primary text-neutral-white"
                        : "bg-neutral-lightGray text-neutral-black"
                    }`}
                  >
                    {option.label}
                    <span
                      title={option.info}
                      className="w-4 h-4 flex items-center justify-center bg-neutral-mediumGray rounded-full text-xs text-neutral-white cursor-help"
                    >
                      i
                    </span>
                  </button>
                ))}
              </div>
            )}
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={monthlyChartData}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="month" tickFormatter={formatMonth} />
                <YAxis />
                <RechartsTooltip
                  labelFormatter={(label) => formatMonth(label)}
                />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#387908" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
