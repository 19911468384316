// Contacts.jsx
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import axiosClient from "../api/axiosClient";

/**
 * Contacts
 * @description
 * Página para gestionar los contactos: creación manual, carga vía CSV,
 * selección y asignación a automatizaciones, envío inmediato de WhatsApp, y eliminación.
 */

// Definición de estilo unificado para los botones
const btnStyle =
  "bg-primary hover:bg-primary-dark text-neutral-white font-bold py-2 px-4 rounded-md transition duration-300";

const Contacts = () => {
  // Estados para el formulario manual
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [prefijo, setPrefijo] = useState("+34");
  const [email, setEmail] = useState("");

  // Estado para la lista de contactos
  const [contacts, setContacts] = useState([]);

  // Estado para la información de la compañía (incluye monthlyLimit)
  const [companyInfo, setCompanyInfo] = useState(null);

  // Estado para el archivo CSV
  const [csvFile, setCsvFile] = useState(null);

  // Spinner de carga
  const [isLoading, setIsLoading] = useState(false);

  // Contactos seleccionados (checkbox)
  const [selectedContacts, setSelectedContacts] = useState([]);

  // Lista de automatizaciones disponibles
  const [automations, setAutomations] = useState([]);
  const [selectedAutomation, setSelectedAutomation] = useState("");

  // Prefijos de ejemplo
  const prefijos = ["+34", "+52", "+1", "+44", "+81", "+357"];

  // Filtro: "all" muestra todos, "contacted" solo contactados, "not_contacted" no contactados
  const [filterType, setFilterType] = useState("all");

  // Nuevo estado para el buscador por nombre y teléfono
  const [searchQuery, setSearchQuery] = useState("");

  // Cargar contactos y automatizaciones al montar el componente
  useEffect(() => {
    fetchContacts();
    fetchAutomations();
  }, []);

  /**
   * fetchContacts
   * Obtiene la lista de contactos del backend.
   */
  const fetchContacts = async () => {
    try {
      setIsLoading(true);
      const response = await axiosClient.get("/contacts");
      console.log("Respuesta de contactos:", response);
      // La respuesta ya viene parseada, se asume que tiene { contacts, companyInfo }
      setContacts(response.contacts);
      setCompanyInfo(response.companyInfo);
    } catch (error) {
      console.error("Error al obtener contactos:", error);
      alert("Error al obtener contactos. Revisa la consola.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * fetchAutomations
   * Obtiene la lista de automatizaciones disponibles para el usuario.
   */
  const fetchAutomations = async () => {
    try {
      const resp = await axiosClient.get("/automations");
      setAutomations(resp);
    } catch (error) {
      console.error("Error fetching automations:", error);
    }
  };

  /**
   * handleSubmitManual
   * Envía un nuevo contacto ingresado manualmente.
   */
  const handleSubmitManual = async (e) => {
    e.preventDefault();
    if (!nombre.trim() || !telefono.trim()) {
      alert("El nombre y el teléfono son obligatorios");
      return;
    }
    if (availableContacts <= 0) {
      alert("Has alcanzado el límite mensual de contactos.");
      return;
    }
    const newContact = {
      nombre,
      telefono: prefijo + telefono,
      email: email || "",
    };
    try {
      await axiosClient.post("/contacts", { contacts: [newContact] });
      alert("Contacto creado exitosamente");
      // Resetear el formulario
      setNombre("");
      setTelefono("");
      setEmail("");
      setPrefijo("+34");
      fetchContacts();
    } catch (error) {
      console.error("Error al crear el contacto:", error);
      alert("Error al crear el contacto. Revisa la consola.");
    }
  };

  /**
   * handleFileUpload
   * Maneja la selección de un archivo CSV.
   */
  const handleFileUpload = (e) => {
    setCsvFile(e.target.files[0]);
  };

  /**
   * handleSubmitCSV
   * Parsea el CSV y envía los contactos al backend.
   */
  const handleSubmitCSV = () => {
    if (!csvFile) {
      alert("Por favor, selecciona un archivo CSV primero");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target.result;
      Papa.parse(fileContent, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const rows = results.data;
          // Validar que cada fila tenga 'nombre' y 'telefono'
          const isValidCsv = rows.every((row) => row.nombre && row.telefono);
          if (!isValidCsv) {
            alert("El CSV debe contener las columnas 'nombre' y 'telefono'.");
            return;
          }
          const parsedContacts = rows
            .map((row) => ({
              nombre: row.nombre.trim() || "",
              telefono: row.telefono ? prefijo + row.telefono.trim() : "",
              email: row.email?.trim() || "",
            }))
            .filter((c) => c.nombre && c.telefono);
          if (parsedContacts.length === 0) {
            alert("No se encontraron filas válidas en el CSV");
            return;
          }
          if (availableContacts <= 0) {
            alert("Has alcanzado el límite mensual de contactos.");
            return;
          }
          try {
            await axiosClient.post("/contacts", { contacts: parsedContacts });
            alert("Contactos cargados exitosamente");
            setCsvFile(null);
            fetchContacts();
          } catch (error) {
            console.error("Error al enviar contactos CSV:", error);
            alert("Ocurrió un error al enviar los contactos CSV");
          }
        },
        error: (error) => {
          console.error("Error al parsear el CSV:", error);
          alert("Ocurrió un error al parsear el CSV");
        },
      });
    };
    reader.onerror = (error) => {
      console.error("Error al leer el archivo:", error);
      alert("Hubo un error al leer el archivo.");
    };
    reader.readAsText(csvFile);
  };

  /**
   * handleSelectContact
   * Marca o desmarca un contacto (checkbox) para su selección.
   */
  const handleSelectContact = (contact) => {
    const isSelected = selectedContacts.some((c) => c.contactId === contact.contactId);
    if (isSelected) {
      setSelectedContacts((prev) => prev.filter((c) => c.contactId !== contact.contactId));
    } else {
      setSelectedContacts((prev) => [...prev, contact]);
    }
  };

  /**
   * handleAssignToAutomation
   * Asocia los contactos seleccionados a la automatización elegida.
   */
  const handleAssignToAutomation = async () => {
    if (!selectedAutomation) {
      alert("Por favor, selecciona una automatización.");
      return;
    }
    if (selectedContacts.length === 0) {
      alert("No hay contactos seleccionados.");
      return;
    }
    try {
      await axiosClient.post(`/automations/${selectedAutomation}/contacts`, {
        contactIds: selectedContacts.map((c) => c.contactId),
      });
      alert("Contactos añadidos a la automatización con éxito.");
      setSelectedContacts([]);
      setSelectedAutomation("");
      fetchContacts();
    } catch (error) {
      console.error("Error al asignar contactos a la automatización:", error);
      alert("Ocurrió un error al asignar los contactos. Revisa la consola.");
    }
  };

  /**
   * handleSendReviewRequests
   * (Opcional) Envía WhatsApp a los contactos seleccionados usando la Lambda de sendSMSReview.
   */
  const handleSendReviewRequests = async () => {
    if (selectedContacts.length === 0) {
      alert("No hay contactos seleccionados para enviar WhatsApp.");
      return;
    }
    setIsLoading(true);
    try {
      for (const contact of selectedContacts) {
        await axiosClient.post("/contacts/sendReviewRequest", {
          userId: contact.userId,
          contactId: contact.contactId,
        });
      }
      alert("WhatsApp enviado(s) satisfactoriamente.");
      setSelectedContacts([]);
      fetchContacts();
    } catch (error) {
      console.error("Error al enviar WhatsApp:", error);
      alert("Hubo un error al enviar WhatsApp.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * handleDeleteContacts
   * Envía una solicitud DELETE al backend para eliminar los contactos seleccionados.
   */
  const handleDeleteContacts = async () => {
    if (selectedContacts.length === 0) {
      alert("No hay contactos seleccionados para eliminar");
      return;
    }
    if (!window.confirm("¿Estás seguro de eliminar los contactos seleccionados?"))
      return;
    try {
      // Se envía la solicitud DELETE incluyendo los contactIds en el body
      await axiosClient.delete("/contacts", {
        data: { contactIds: selectedContacts.map((c) => c.contactId) },
      });
      alert("Contacto(s) eliminados con éxito");
      setSelectedContacts([]);
      fetchContacts();
    } catch (error) {
      console.error("Error al eliminar contactos:", error);
      alert("Error al eliminar contactos. Revisa la consola.");
    }
  };

  /**
   * handleFilterChange
   * Cambia el tipo de filtro (todos, contactados, no contactados).
   */
  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  // Filtrar contactos según el filtro seleccionado y el término de búsqueda (por nombre y teléfono)
  const filteredContacts = React.useMemo(() => {
    return contacts.filter((c) => {
      const matchesSearch =
        !searchQuery.trim() ||
        (c.nombre && c.nombre.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (c.telefono && c.telefono.toLowerCase().includes(searchQuery.toLowerCase()));
      let matchesFilter = true;
      if (filterType === "contacted") {
        matchesFilter = c.contactado;
      } else if (filterType === "not_contacted") {
        matchesFilter = !c.contactado;
      }
      return matchesSearch && matchesFilter;
    });
  }, [contacts, searchQuery, filterType]);

  /**
   * handleSelectAllNotContacted
   * Selecciona todos los contactos que NO estén contactados.
   */
  const handleSelectAllNotContacted = () => {
    const notContacted = contacts.filter((c) => !c.contactado);
    setSelectedContacts(notContacted);
  };

  // Calcular contactos usados este mes (usando lastInteraction y contactado=true)
  const usedContacts = contacts.filter((c) => {
    if (!c.contactado || !c.lastInteraction) return false;
    const lastInteraction = new Date(c.lastInteraction);
    const now = new Date();
    return lastInteraction.getMonth() === now.getMonth() && lastInteraction.getFullYear() === now.getFullYear();
  }).length;

  // Límite mensual y contactos disponibles
  const monthlyLimit = companyInfo?.monthlyLimit ? Number(companyInfo.monthlyLimit) : Infinity;
  const availableContacts = monthlyLimit - usedContacts;

  return (
    <div className="p-4">
      <h1 className="font-heading text-h2 text-neutral-black mb-4">Contactos</h1>

      {/* Mostrar información del límite */}
      <div className="mb-4 p-4 border border-neutral-mediumGray rounded-md bg-neutral-lightGray">
        <p className="text-neutral-darkGray">
          Límite mensual: <span className="font-bold">{monthlyLimit}</span>
        </p>
        <p className="text-neutral-darkGray">
          Contactos usados este mes: <span className="font-bold">{usedContacts}</span>
        </p>
        <p className="text-neutral-darkGray">
          Contactos disponibles: <span className="font-bold">{availableContacts}</span>
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-6 mb-6">
        <form onSubmit={handleSubmitManual} className="flex-1 p-4 border border-neutral-mediumGray rounded-md">
          <h2 className="font-heading text-h4 text-neutral-black mb-2">Agregar Contacto Manualmente</h2>
          <div className="mb-4">
            <label className="block mb-1 font-medium text-neutral-darkGray">Nombre *</label>
            <input type="text" className="border border-neutral-mediumGray p-2 w-full rounded-md" placeholder="Ej: Juan Pérez" value={nombre} onChange={(e) => setNombre(e.target.value)} />
          </div>
          <div className="mb-4 flex items-center gap-2">
            <div className="w-1/3">
              <label className="block mb-1 font-medium text-neutral-darkGray">Prefijo</label>
              <select className="border border-neutral-mediumGray p-2 rounded-md w-full" value={prefijo} onChange={(e) => setPrefijo(e.target.value)}>
                {prefijos.map((p) => (<option key={p} value={p}>{p}</option>))}
              </select>
            </div>
            <div className="flex-1">
              <label className="block mb-1 font-medium text-neutral-darkGray">Teléfono *</label>
              <input type="text" className="border border-neutral-mediumGray p-2 w-full rounded-md" placeholder="Ej: 600123123" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium text-neutral-darkGray">Email (opcional)</label>
            <input type="email" className="border border-neutral-mediumGray p-2 w-full rounded-md" placeholder="Ej: correo@dominio.com" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <button
            type="submit"
            className={`${btnStyle} ${availableContacts <= 0 ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={availableContacts <= 0}
            title={availableContacts <= 0 ? "Has alcanzado el límite mensual de contactos." : ""}
          >
            Agregar
          </button>
        </form>

        {/* Sección para cargar contactos vía CSV */}
        <div className="flex-1 p-4 border border-neutral-mediumGray rounded-md">
          <h2 className="font-heading text-h4 text-neutral-black mb-2">Cargar Contactos desde CSV</h2>
          <div className="mb-4">
            <input type="file" accept=".csv" onChange={handleFileUpload} className="w-full" />
          </div>
          <button
            onClick={handleSubmitCSV}
            className={`${btnStyle} ${availableContacts <= 0 ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={availableContacts <= 0}
            title={availableContacts <= 0 ? "Has alcanzado el límite mensual de contactos." : ""}
          >
            Subir CSV
          </button>
        </div>
      </div>

      {/* Controles de filtro y selección */}
      <div className="flex items-center gap-4 mb-4">
        <div className="flex items-center gap-2">
          <label className="font-medium text-neutral-darkGray">Filtrar:</label>
          <select value={filterType} onChange={handleFilterChange} className="border border-neutral-mediumGray p-2 rounded-md">
            <option value="all">Todos</option>
            <option value="contacted">Contactados</option>
            <option value="not_contacted">No Contactados</option>
          </select>
        </div>
        <button onClick={handleSelectAllNotContacted} className={btnStyle}>Seleccionar todos (No Contactados)</button>
      </div>

      {/* Acciones para contactos seleccionados */}
      {selectedContacts.length > 0 && (
        <div className="mb-4 flex flex-wrap items-center gap-4">
          <p className="text-sm text-neutral-darkGray">{selectedContacts.length} contacto(s) seleccionado(s).</p>
          <button onClick={handleSendReviewRequests} className={btnStyle}>Enviar WhatsApp Inmediato</button>
          <button onClick={handleDeleteContacts} className={`${btnStyle} bg-red-500 hover:bg-red-600`}>Eliminar Contacto(s)</button>
          <div>
            <label className="block mb-1 font-medium text-neutral-darkGray">Automatización:</label>
            <select value={selectedAutomation} onChange={(e) => setSelectedAutomation(e.target.value)} className="border border-neutral-mediumGray p-2 rounded-md">
              <option value="">-- Seleccionar --</option>
              {automations.map((auto) => (
                <option key={auto.automationId} value={auto.automationId}>
                  {auto.automationName}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handleAssignToAutomation} className={btnStyle}>Agregar a Automatización</button>
        </div>
      )}

      {/* Nuevo buscador por nombre y teléfono */}
      <div className="mb-4">
        <label className="block mb-1 font-medium text-neutral-darkGray">Buscar:</label>
        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Buscar por nombre o teléfono" className="border border-neutral-mediumGray p-2 rounded-md w-full" />
      </div>

      {/* Tabla de contactos */}
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-neutral-white shadow-card rounded-lg">
            <thead>
              <tr className="bg-neutral-lightGray border-b border-neutral-mediumGray">
                <th className="p-4 text-left">Sel</th>
                <th className="p-4 text-left">Nombre</th>
                <th className="p-4 text-left">Teléfono</th>
                <th className="p-4 text-left">Email</th>
                <th className="p-4 text-left">Contactado</th>
                <th className="p-4 text-left">Automatización</th>
              </tr>
            </thead>
            <tbody>
              {filteredContacts.map((contact) => {
                const isSelected = selectedContacts.some((c) => c.contactId === contact.contactId);
                const automationName = contact.automationId
                  ? automations.find((auto) => auto.automationId === contact.automationId)?.automationName || contact.automationId
                  : "-";
                return (
                  <tr key={contact.contactId} className="border-b hover:bg-neutral-lightGray">
                    <td className="p-4">
                      <input type="checkbox" checked={isSelected} onChange={() => handleSelectContact(contact)} />
                    </td>
                    <td className="p-4">{contact.nombre}</td>
                    <td className="p-4">{contact.telefono}</td>
                    <td className="p-4">{contact.email || "N/A"}</td>
                    <td className="p-4">{contact.contactado ? "Sí" : "No"}</td>
                    <td className="p-4">{automationName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Contacts;
