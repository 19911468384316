import React from "react";
import { Link } from "react-router-dom";
import {
  FaGoogle,
  FaHome,
  FaAddressBook,
  FaStar,
  FaComment,
  FaCogs,
  FaComments,
} from "react-icons/fa";

/**
 * Sidebar
 * @description
 * Menú lateral con las secciones principales de la aplicación:
 * Conectar Google, Dashboard, Contactos y Reviews.
 */
const Sidebar = () => {
  return (
    <aside className="w-64 bg-primary-dark text-white h-screen sticky top-0 flex flex-col">
      <div className="p-4 font-heading text-h3 text-white border-b border-primary-light flex items-center space-x-2">
        <span className="bg-primary-light text-primary-dark px-3 py-1 rounded-lg">
          BUKZ
        </span>
      </div>
      <nav className="mt-4 flex flex-col">
        <Link
          to="/connect-google"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaGoogle className="mr-3" />
          <span>Conectar Google</span>
        </Link>
        <Link
          to="/dashboard"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaHome className="mr-3" />
          <span>Dashboard</span>
        </Link>
        <Link
          to="/contacts"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaAddressBook className="mr-3" />
          <span>Contactos</span>
        </Link>
        {/* ruta chats */}
        <Link
          to="/chats"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaComments className="mr-3" />
          <span>Chats</span>
        </Link>
        {/* ruta automations */}
        <Link
          to="/automations"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaCogs className="mr-3" />
          <span>Automatizaciones</span>
        </Link>
        <Link
          to="/reviews"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaStar className="mr-3" />
          <span>Reviews</span>
        </Link>
        {/* Link to the Feedback page */}
        <Link
          to="/feedback"
          className="flex items-center px-4 py-2 hover:bg-primary transition-colors rounded-lg font-medium"
        >
          <FaComment className="mr-3" />
          <span>Feedback</span>
        </Link>
      </nav>
    </aside>
  );
};

export default Sidebar;
