import React, { useState } from "react";

/**
 * DripForm
 * @description
 * Formulario para configurar la automatización (Drip Mode).
 * onSave: función callback que recibe el objeto dripConfig
 */
const DripForm = ({ onSave }) => {
  const [automationName, setAutomationName] = useState("");
  const [batchQuantity, setBatchQuantity] = useState(10);
  const [frequencyDays, setFrequencyDays] = useState(2);
  const [selectedDays, setSelectedDays] = useState([
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
  ]);
  const [startDate, setStartDate] = useState("");
  const [startHour, setStartHour] = useState("09:00");
  const [endHour, setEndHour] = useState("17:00");

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dripConfig = {
      automationName,
      batchQuantity,
      frequencyDays,
      selectedDays,
      startDate,
      startHour,
      endHour,
    };
    onSave(dripConfig);
  };

  return (
    <form onSubmit={handleSubmit} className="border border-neutral-mediumGray p-4 rounded-xl shadow-card">
      <h3 className="font-heading text-h4 text-neutral-darkGray mb-2">Configuración Drip</h3>
      <div className="mb-2">
        <label className="block mb-1 font-medium text-neutral-darkGray">
          Nombre de la Automatización
        </label>
        <input
          type="text"
          className="border border-neutral-mediumGray p-2 rounded w-full"
          value={automationName}
          onChange={(e) => setAutomationName(e.target.value)}
          required
        />
      </div>
      <div className="mb-2">
        <label className="block mb-1 font-medium text-neutral-darkGray">Batch Quantity</label>
        <input
          type="number"
          className="border border-neutral-mediumGray p-2 rounded w-full"
          value={batchQuantity}
          onChange={(e) => setBatchQuantity(Number(e.target.value))}
          required
        />
      </div>
      <div className="mb-2">
        <label className="block mb-1 font-medium text-neutral-darkGray">Enviar cada X días</label>
        <input
          type="number"
          className="border border-neutral-mediumGray p-2 rounded w-full"
          value={frequencyDays}
          onChange={(e) => setFrequencyDays(Number(e.target.value))}
          required
        />
      </div>
      <div className="mb-2">
        <label className="block mb-1 font-medium text-neutral-darkGray">Fecha de inicio</label>
        <input
          type="date"
          className="border border-neutral-mediumGray p-2 rounded w-full"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </div>
      <div className="mb-2 flex flex-wrap gap-2">
        <label className="block w-full font-medium text-neutral-darkGray">Días permitidos</label>
        {daysOfWeek.map((day) => (
          <button
            key={day}
            type="button"
            onClick={() => toggleDay(day)}
            className={
              selectedDays.includes(day)
                ? "bg-primary text-neutral-white px-3 py-1 rounded-lg font-medium"
                : "bg-neutral-lightGray text-neutral-darkGray px-3 py-1 rounded-lg font-medium"
            }
          >
            {day}
          </button>
        ))}
      </div>
      <div className="mb-2 flex gap-2">
        <div className="flex-1">
          <label className="block mb-1 font-medium text-neutral-darkGray">Hora Inicio</label>
          <input
            type="time"
            className="border border-neutral-mediumGray p-2 rounded w-full"
            value={startHour}
            onChange={(e) => setStartHour(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label className="block mb-1 font-medium text-neutral-darkGray">Hora Fin</label>
          <input
            type="time"
            className="border border-neutral-mediumGray p-2 rounded w-full"
            value={endHour}
            onChange={(e) => setEndHour(e.target.value)}
          />
        </div>
      </div>
      <button className="bg-state-success text-neutral-white px-4 py-2 rounded mt-2 font-medium hover:bg-state-success/90 transition-colors">
        Guardar
      </button>
    </form>
  );
};

export default DripForm;
